@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --orange: #ff3800;
  --colorBg: #F6F5FA;
}
body {
  margin: 0;
  background: var(--colorBg);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-light {
  background: rgba(250, 250, 255, 0.7) !important;
  backdrop-filter: blur(50px) !important;
}


.bg {
  background: rgba(250, 250, 255) !important;
}

.btn-light{
  background: rgba(250, 250, 255, 0.7) !important;
}

.card-blur {
  backdrop-filter: blur(50px)!important;
  padding: 35px;
}

.icon {
  display: block;
  position: relative;
  width: 100px;
  height: 50px;
  background: url('assets/logodark.png') center center no-repeat;
  background-size: contain;
  margin-top: -20px;
}

.icon-dash {
  display: block;
  position: relative;
  width: 100px;
  height: 50px;
  background: url('assets/logodark.png') center center no-repeat;
  background-size: contain;
}

.lang {
  display: inline-block;
  position: relative;
  width: 26px;
  height: 26px;
  margin-bottom: -7px;
  margin-right: 3px;
  background: url('assets/langdark.png') center center no-repeat;
  background-size: contain;
}

.menu {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 17px;
  background: url('assets/menu.png') center center no-repeat;
  background-size: contain;
}

.point-title {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 60px;
  margin-right: 20px;
  margin-bottom: -11px;
  background: url('assets/menu.png') center center no-repeat;
  background-size: contain;
}

.point {
  display: inline-block;
  position: relative;
  width: 13px;
  height: 17px;
  background: url('assets/icon.png') center center no-repeat;
  background-size: contain;
}

.clock {
  display: block;
  position: relative;
  width: 130px;
  height: 130px;
  margin: 30px auto;
  background: url('assets/clock.png') center center no-repeat;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 90px;
  background-size: contain;
}

.lock {
  display: block;
  position: relative;
  width: 130px;
  height: 130px;
  margin: 30px auto;
  background: url('assets/lock.png') center center no-repeat;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 90px;
}

.cardlogo {
  display: block;
  position: relative;
  width: 130px;
  height: 130px;
  margin:  30px auto;
  background: url('assets/logob.png') center center no-repeat;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 90px;
}

.card-orange {
  display: inline-block;
  position: relative;
  padding: 35px;
  border: none;
  background: rgba(0, 0, 0, 0.1) !important;
  margin: 30px;
  border-radius: 50px;
  background: transparent;
  transition-duration: 300ms;
}

.card-orange:hover {
  transform: scale(1.05);
  transition-duration: 200ms;
}

.dropdown-btn {
  font-weight: 800;
  margin-top: -3px;
  margin-right: -50px;
}

.dropdown-menu {
  background: white;
  border: none;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  font-size: 13px;
}

.dropdown-item:active {
   background: var(--orange) !important;
}

.font-a {
  font-size: 14px;
}

li a {
  display: inline-block;
  position: relative;
  color: black !important;
  text-decoration: none;
  transition-duration: 300ms;
  padding: 7px 15px;

}

li a:hover {
  transition-duration: 300ms;
  font-weight: 600;

}

li a.active {
  color: black !important;
  font-weight: 600;
}

li span {
  display: inline-block;
  position: relative;
  color: black !important;
  text-decoration: none;
  transition-duration: 300ms;
  padding: 7px 15px;
  cursor: pointer;

}

li span:hover {
  font-weight: 800 !important;
  transition-duration: 300ms;
}

li span.active {
  font-weight: 800 !important;
}

.btn-orange {
  color: white;
  background-color: var(--orange);
  border: 1px solid var(--orange);
  padding: 5px;
  font-weight: 700;
  padding-left: 30px;
  padding-right: 30px;
}

.navbar-toggler {
  border: none !important;
}

header {
  display: flex;
  height: 100vh;
  align-items: center;
}

.bg-driver {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100vh; 
  background: url('assets/driver.png') center center no-repeat;
  background-size: cover;
}

.bg-login-driver {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100vh; 
  background: url('assets/driver.png') center center no-repeat;
  background-size: cover;
  padding: 9px;
}

.bg-login-rider {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100vh; 
  background: url('assets/header.png') center center no-repeat;
  background-size: cover;
  padding: 9px;
}

.logo-light {
  display: inline-block;
  position: relative;
  width: 130px;
  height: 60px;
  margin: auto auto;
  margin-bottom: 50px;
  background: url('assets/logolight.png') center center no-repeat;
  background-size: contain;
}

.logo-footer {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 50px;
  margin-bottom: 20px;
  background: url('assets/logolight.png') center center no-repeat;
  background-size: contain;
}


.logo-driver {
  display: block;
  position: relative;
  width: 130px;
  height: 130px;
  margin: auto;
  background: url('assets/logodriver.png') center center no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.logo-driver:hover {
  transform: scale(1.05);
  transition-duration: 200ms;
}

.logo-rider {
  display: block;
  position: relative;
  width: 130px;
  height: 130px;
  margin: auto;
  background: url('assets/logob.png') center center no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.logo-rider:hover {
  transform: scale(1.05);
  transition-duration: 200ms;
}

.small {
  font-size: 12px
}

.logo-driver-sm {
  display: inline-block;
  position: relative;
  width: 39px;
  height: 39px;
  background: url('assets/logodriver.png') center center no-repeat;
  border: 1px solid rgba(250, 250, 255, 0.3);
  background-size: cover;
  margin-top: -7px;
  border-radius: 50%;
}

.logo-rider-sm {
  display: inline-block;
  position: relative;
  width: 39px;
  height: 39px;
  border: 1px solid rgba(250, 250, 255, 0.3);
  background: url('assets/logob.png') center center no-repeat;
  background-size: cover;
  margin-top: -7px;
  border-radius: 50%;
}


.header-logo {
  display: inline-block;
  position: relative;
  width: 205px;
  height: 95px; 
  margin: 35px auto;
  background: url('assets/logo.png') center center no-repeat;
  background-size: contain;
}

.header-img {
  display: inline-block;
  position: absolute;
  width: 100vw;
  height: 100vh; 
  right: 0;
  bottom: 0vh;
  margin: auto auto;
  background: url('assets/header.png') center center no-repeat;
  background-size: cover;
  z-index: -1;
  filter: grayscale(10%) brightness(70%);
}

.logob {
  display: inline-block;
  position: absolute;
  width: 70px;
  height: 70px; 
  top: -110px;
  left: 0;
  right: 0;
  margin: 35px auto;
  background: url('assets/logob.png') center right no-repeat;
  background-size: contain;

}

.logoa {
  display: inline-block;
  position: absolute;
  width: 70px;
  height: 70px; 
  top: -110px;
  left: 0;
  right: 0;
  margin: 35px auto;
  background: url('assets/logodriver.png') center right no-repeat;
  background-size: contain;

}

.header-play {
  display: block;
  position: relative;
  height: 40px;
  left: 90px;
}

h1 {
  font-weight: 700;
  font-size: 50px;
  color: #000
}

h2 {
  font-weight: 800
}

p {
  font-weight: 300;
}

.w300 {
  font-weight: 300;
}

.w500 {
  font-weight: 500;
}

.w700 {
  font-weight: 700;
}

.p2 {
  font-size: 15px;
}

.img-silde {
  display: block;
  position: relative;
  height: 100%;
}

.slide {
  height: auto;
  margin: 30px auto
}

.slide img {
  display: block;
  position: relative;
  margin: auto auto;
  width: 200px;
}

.carousel-control-next-icon,.carousel-control-prev-icon, .carousel-indicators {
  filter: invert(100%);
}

.number {
  display: inline-block;
  position: relative;
  background-color: var(--orange);
  width: 37px;
  height: 37px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 25px;
  font-weight:700;
  margin: 0;
}

.numberOff {
  display: inline-block;
  position: relative;
  border: 1px solid var(--orange);
  width: 37px;
  height: 37px;
  border-radius: 50%;
  text-align: center;
  color: black;
  font-size: 25px;
  font-weight:700;
  margin: 0;
}

.text-off {
  opacity: 0.35;
}

.accordion-item {
  margin: 35px auto;
  border-radius: 15px !important;
  background: rgba(0, 0, 0, 0.1);
  border: none !important;
}

.accordion-button {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  border-radius: 15px !important;
  font-size: 20px;
}


.accordion-button:active {
  background-color: transparent !important;
}

.icon-orange {
  display: inline-block;
  position: relative;
  font-size: 45px;
  margin: 13px;
  color: var(--orange);
  background-size: contain;
}

.icon-white {
  display: inline-block;
  position: relative;
  font-size: 30px;
  margin: 10px;
  color: var(--orange);
  background-size: contain;
}

.wtp-aux {
  display: inline-block;
  position: fixed;
  width: auto;
  right: 9vw;
  bottom:50px;
  text-decoration: none;
  z-index: 2;
  color: white;
  background: rgba(0, 0, 0, 1);
  border: 1px solid rgba(250, 250, 255, 0.3);
  backdrop-filter: blur(10px);
  font-size: 15px;
  font-weight: bold;
  border-radius: 30px;
  padding: 9px 13px;
  padding-right: 40px;
}

.wtp-icon {
  font-size: 33px;
  position: absolute;
  margin-top: -5px;
  margin-left: 3px;
}

footer {
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 50px;
  background-color: black;
  bottom: 0;
  margin-bottom: -10px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.img-slide {
  display: block;
  position: relative;
  width: 250px;
}

.subtext {
  display: block;
  position: absolute;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 800;
  margin: auto -7px;
  color: black;
  padding: 0;
  bottom: -11px;
  right: 0;
  left: 0;
  text-align: center;
  border-radius: 15px;
}

.subtext-white {
  display: block;
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 1px; 
  font-size: 8px;
  font-weight: 800;
  margin: auto -7px;
  color: white;
  padding: 0;
  bottom: -15px;
  right: 0;
  left: 0;
  text-align: center;
  border-radius: 15px;
}

.subtextAux {
  display: block;
  position: absolute;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px; 
  font-weight: 500;
  color: black;
  bottom: -20px;
  width: 100%;
  text-align: center;
  border-radius: 15px;
}

.table-responsive {
  max-width: 80vw;
}

@media only screen and (max-width: 700px) {

  .bg-light {
    backdrop-filter: blur(15px) !important;
  }

  .table-responsive {
    max-width: 100vw;
  }

  li{
    display: block;
    position: relative;
    width: 100%;
    text-align: right;
  }

  .dropdown-menu li {
    text-align: left ;
  }


  .icon {
    width: 100px;
    height: 30px;
    margin-top: -0px;
  }

  .icon-dash {
    width: 100px;
    height: 30px;
    margin-top: -0px;
  }

  .header-logo {
    width: 200px;
    height: 70px; 
    margin-top: 52vh;
  }


  h1 {
    font-size: 25px;
  }

  h2 {
    font-size:20px;
  }

  p {
    font-size: 12px;
  }

  .p2 {
    font-size: 12px;
  }

  .accordion-item {
    margin: 25px auto;
  }

  .text-off {
    display: none;
  }

  .number {
    width: 27px;
    height: 27px;
    font-size: 19px;
    margin-left: 10px;
  }

  .bg-driver {
    align-items: end;
  }
  
  .logo-light {
    width: 100px;
    height: 60px;
    margin-bottom: 1vh;
  }

  .logo-driver {
    width: 70px;
    height: 70px;
  }

  .logo-rider {
    width: 70px;
    height: 70px;
  }

  .card-orange {
    display: inline-block;
    position: relative;
    padding: 20px;
    margin: 20px;
    border-radius: 40px;
    background: transparent;
    transition-duration: 300ms;
  }
  
  .card-blur {
    backdrop-filter: blur(50px)!important;
    padding: 25px;
  }

  .wtp-aux {
    right: 5px;
    bottom:5px;
  }

  .accordion-button {
    font-size: 17px;
  }

  .header-play {
    display: block;
    position: relative;
    height: 25px;
    left: 50px;
  }  

  .bg-move {
    animation-name: bganimate;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
  }

}

@keyframes bganimate {
  from {
    background-position: center left ;
    }
  
    to {
        background-position: center right ;
    }
  }